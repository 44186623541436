import Top from "../components/service/top";
const OurServicesPage: React.FC = () => {
  return (
    <>
      <Top />
    </>
  );
};

export default OurServicesPage;
